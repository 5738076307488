<script lang="ts" setup>
import { useAPIHandle } from 'core'

import Card from './common/Card.vue'
import SwitchGroup from './common/SwitchGroup.vue'

const { settings } = useSession()
const user = settings.user

const [state, uploadAvatar] = useAPIHandle(() => settings.updateAvatar())
</script>

<template>
  <Card title="Account" class="sm:grid-cols-[1fr_max-content]">
    <InputGroup v-model.trim="user.name" label="Account name" placeholder="Your name" />

    <InputGroup label="Profile Picture">
      <div class="flex items-center gap-4">
        <Avatar :name="user.name" :src="user.avatarUrl" size="48" />

        <IconButton variant="secondary" size="lg" title="Upload new avatar" :loading="state.loading" icon="i-solar-upload-line-duotone" @click="uploadAvatar" />
      </div>
    </InputGroup>
  </Card>

  <Card title="General">
    <SwitchGroup
      v-model="settings.defaults.notifications"
      title="Email notifications"
      description="Get notified when your voice notes are opened, monthly updates and more"
    />
    <SwitchGroup
      v-model="settings.defaults.transcriptions"
      title="Transcriptions"
      description="Automatically transcribes your voice recordings. Your listeners can see the transcription in the web player."
    />
    <SwitchGroup
      v-model="settings.listenPage.comments.enabled"
      title="Comments"
      description="Allows listeners to comment on your recordings in the listen page. Enable this to foster engagement and receive feedback from your audience."
    />
    <SwitchGroup
      v-model="settings.attachment.enabled"
      title="Enable mp3 attachments"
      description="If enabled, a mp3 file with your voice note will be attached to emails."
    />
  </Card>
</template>
