<template>
  <main class="grid grid-rows-[min-content] gap-8 sm:gap-12 2xl:place-content-center relative">
    <PageSettingsAccount />
    <LazyPageSettingsDomain hydrate-on-visible />
    <LazyPageSettingsSignature hydrate-on-visible />
    <LazyPageSettingsListen hydrate-on-visible />
    <LazyPageSettingsSubscription hydrate-on-visible />
    <LazyPageSettingsTeam hydrate-on-visible />
    <LazyPageSettingsReferral hydrate-on-visible />
    <LazyPageSettingsDelete hydrate-on-visible />
  </main>
</template>
