<script lang="ts" setup>
import { useUID } from 'core'

defineProps<{
  label?: string
  title: string
  description?: string
}>()

const id = useUID('settings-group-item-switch')
const modelValue = defineModel<boolean>()
</script>

<template>
  <div>
    <label v-if="label" :for="id" class="block mb-3 text-sm text-neutral-500 font-semibold">{{ label }}</label>
    <label class="gap-6 flex items-start <xs:flex-col">
      <Switch :id v-model="modelValue" :title />

      <section>
        <h4 class="mb-3 text-base text-neutral-600 font-medium">{{ title }}</h4>
        <p v-if="description" class="text-sm text-neutral-500 sm:text-base">{{ description }}</p>
      </section>
    </label>
  </div>
</template>
